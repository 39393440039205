// firebase.js

// import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics"
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';


/*const firebaseConfig = { //For Production
  apiKey: "AIzaSyC90tkFRMCBdE7lnntORfpzCXspsqYuWQk",
  authDomain: "koppoh-4e5fb.firebaseapp.com",
  databaseURL: "https://koppoh-4e5fb-default-rtdb.firebaseio.com",
  projectId: "koppoh-4e5fb",
  storageBucket: "koppoh-4e5fb.appspot.com",
  messagingSenderId: "324777107146",
  appId: "1:324777107146:web:7116f780e1dfb30f1e1e33",
  measurementId: "G-7F0L8GN77J"
};*/
const firebaseConfig = {
  apiKey: "AIzaSyB5V8FVm3bsp0A4jDrARqulZUkI_23FDgM",
  authDomain: "koppoh-362da.firebaseapp.com",
  databaseURL: "https://koppoh-362da-default-rtdb.firebaseio.com",
  projectId: "koppoh-362da",
  storageBucket: "koppoh-362da.appspot.com",
  messagingSenderId: "972921042875",
  appId: "1:972921042875:web:47193dbda9881b7b80769e",
  measurementId: "G-8K3YRLJ8BC"
};

// firebase.initializeApp(firebaseConfig);
// export default firebase;

// const app = initializeApp(firebaseConfig);
// export default app;
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
export { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup };
// const analytics = getAnalytics(app);
// export { getAuth, signInWithPopup, GoogleAuthProvider };
