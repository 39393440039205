import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '../components/services/auth';
import TheTeaserVue from '@/pages/app/TheTeaser.vue';

const requireAuth = (to, from, next) => {
  console.log("coming from: ", from)
  if (!isAuthenticated() && from.name == "Signup") {
    // If the user is not authenticated, redirect to the login page
    next({ name: 'Signup' });
  }else if (!isAuthenticated() && !from.name == "Signup") {
    // If the user is not authenticated, redirect to the login page
    next({ name: 'Login' });
  } else {
    // If the user is authenticated, proceed to the requested route
    next();
  }
};

const routes = [
  // {
  //   path: '/2',
  //   name: 'LandingPage',
  //   component : () => import("../pages/LandingPage.vue")
  // },
  {
    path: '/',
    name: 'LandingPageNew',
    component : () => import("../pages/LandingPageNew.vue")
  },
  {
    path: '/investors-home',
    name: 'Investors Home',
    component : () => import("../pages/InvestorsHome.vue")
  },
  {
    path: '/about-us',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/AboutUs.vue')
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component : () => import("../pages/Faqs.vue")
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component : () => import("../pages/Contact.vue")
  },
  {
    path: '/our-partners',
    name: 'Partners',
    component : () => import("../pages/Partners.vue")
  },
  {
    path: '/our-solutions',
    name: 'Our Solutions',
    component : () => import("../pages/OurSolutions.vue")
  },
  {
    path: '/investor-universe',
    name: 'Investor universe',
    component : () => import("../pages/investor/InvestorUniverse.vue")
  },
  // App Begins here Authenticiation
  {
    path: '/login/identifier',
    name: 'Login',
    component : () => import("../pages/authentication/Login.vue"),
  },
  {
    path: '/login-password', // Define userEmail as a route parameter
    name: 'LoginPassword',
    component : () => import("@/components/authentication/LoginPassword.vue"),
  },
  {
    path: '/congratulation',
    name: 'Congratulation',
    component : () => import("@/components/reusable/Congratulation.vue")
  },
  {
    path: '/sign-up',
    name: 'Signup',
    component : () => import("../pages/authentication/Signup.vue"),
  },
  {
    path: '/verify-email/:userEmail',
    name: 'EmailVerification',
    component : () => import("../components/authentication/EmailVerification.vue"),
    props: true
  },
  {
    path: '/confirm-verification/',
    name: 'VerificationFromMail',
    component : () => import("../components/authentication/EmailVerification.vue"),
    props: true
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component : () => import("../components/authentication/ForgetPassword.vue")
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component : () => import("../components/authentication/PasswordReset.vue")
  },
    // Autheniciation ends
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('../pages/app/Profile.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/overview',
      name: 'Overview',
      component: () => import('../pages/app/Overview.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/templates',
      name: 'Template',
      component: () => import('../pages/app/Template.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/application',
      name: 'Application',
      component: () => import('../pages/app/Application.vue'),
      beforeEnter: requireAuth,
      
    },
    {
      path: '/subscription',
      name: 'Subscription',
      component: () => import('../pages/app/Subscription.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/request-fund/:status',
      name: 'Request Fund',
      component: () => import('../pages/app/RequestFund.vue'),
      beforeEnter: requireAuth,
      props: route => ({
        enableEdit: route.params.enableEdit === 'true', // Convert string to boolean
      }),
    },  
    {
      path: '/apply-for-fund',
      name: 'Apply for Fund',
      component: () => import('../pages/app/Apply4Fund.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/select-guided',
      name: 'Guided Application Funding',
      component: () => import('../pages/app/SelectGuidedApplication.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/select-payment',
      name: 'Choose Payment',
      component: () => import('../pages/app/ChoosePayment.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/bulk-application',
      name: 'Bulk Application',
      component: () => import('../pages/app/BulkApplication.vue'),
      beforeEnter: requireAuth,
    }, 
    {
      path: '/bulk-application/view',
      name: 'Bulk Application Info',
      component: () => import('../pages/app/BulkApplicationInfo.vue'),
      beforeEnter: requireAuth,
    },    
    {
      path: '/create-teaser',
      name: 'Create Teaser',
      component: () => import('../pages/app/PitchTeaser.vue'),
      beforeEnter: requireAuth,
    },
    {
      path: '/get-teaser',
      name: 'Get Teaser',
      component: () => import('../pages/app/TheTeaser.vue'),
    },
    {
      path: '/get-teaser-big',
      name: 'Get Teaser Big',
      component: () => import('../pages/app/TheTeaserBIG.vue'),
    },
  
  // Legal Terms and Privacy Policy
  {
    path: '/legal/terms',
    name: 'Terms',
    component : () => import("@/pages/legal/Terms.vue")
  },
  {
    path: '/legal/privacy-policy',
    name: 'PrivacyPolicy',
    component : () => import("@/pages/legal/PrivacyPolicy.vue")
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  transition: 'fade', // Set the default transition class
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});
// router.beforeEach((to, from, next) => {
//   const fromApplication = from.meta.fromApplication || false;
//   sessionStorage.setItem('comingFromApplication', fromApplication);
//   window.scrollTo(0, 0);
//   next();
// });
export default router
